<template>
  <div>
    <a-table
      :columns="columns"
      :row-key="record => record.id"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :locale="locale"             
      @change="handleTableChange"
    >
      <template slot="sdate" slot-scope="record">
            <b-icon icon="calendar3" aria-hidden="true"></b-icon>
            <span v-text="' '+fdateformat(record.sdate)"></span>              
      </template>
      <template slot="actitle" slot-scope="record">
            <span v-text="record.actitle"></span>            
      </template>
      <template slot="action" slot-scope="record">        
        <a  @click="del(record)" href="javascript: void(0)" >
              <b-icon icon="trash" font-scale="1.2" variant="danger"></b-icon> 刪除 
        </a>         
        <router-link
          :to="{
                name:'ActTmp', 
                params: { id: record.id}
                }"
        >
          <b-icon icon="pencil-square" font-scale="1.2"></b-icon>編輯
        </router-link>       
      </template>
    </a-table>
  </div>
</template>   
<script>
import * as moment from "moment/moment";
import { actService, macService } from "../../../_services";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.Findactpagebystatetmp(params);
};

const delData = params => {
  return macService.macDelTmp(params);
}

const columns = [
  {
    title: "活動日期",
    dataIndex: "",
    width: "15%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "55%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: " 編輯 ",
    dataIndex: "",
    key: "id",
    ellipsis: true,
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "Act_table_tmp",
  props: {
    ids: {
      type: String
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      data: [],
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },
    };
  },
  computed: {
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    //console.log("mounted-reload");
    this.reload();
  },
  watch: {
    ids() {
      //console.log("watch-reload");
      this.reload();
    },
    defaultval() {
      //console.log("watch-defaultval");
      this.reload();
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      //console.log(pagination);
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      //console.log(this.pagination);
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.defaultval.id,
        state: this.ids,
        ...filters
      });
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        const pagination = { ...this.pagination };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    del(items){
      const self = this;
      this.$confirm({
        title:
          "您確定刪除該活動?",
        content: "活動名稱 : " + items.actitle,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        zIndex: 9999,
        async onOk() {
          await self.removem(items.id);
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },   
    
    async removem(id) {
      this.loading = true;
      let rsults = await this.updata({id})        
      setTimeout(() => {
        if(rsults.data==null || rsults.data==''){
          this.reload();
        } 
      }, 1600);      
    },    

    async updata(params={}){
     return await delData({
        ...params,
      }).then(({ data }) => {  
        let obj = data;      
        return obj
      });
    },    

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "I" ? (tmp = "審核中") : "";
      val == "Y" ? (tmp = "發佈中") : "";
      val == "F" ? (tmp = "結束") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    reload() {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.defaultval.id,
        state: this.ids
      });
    }
  }
};
</script>

<style scoped>
.myTable thead {
  background-color: #000000;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
</style>