<template>
  <div>
    <a-table
      :columns="columns"
      :row-key="record => record.id"
      :data-source="data"
      :pagination="pagination"
      :loading="loading"
      :locale="locale"             
      @change="handleTableChange"
    >
      <template slot="sdate" slot-scope="record">
        <router-link :to="{name:'Act_date',params:{ id: record.id}}">
          <b-button variant="link" size="sm">
            <b-icon icon="calendar3" aria-hidden="true"></b-icon>
            <span v-text="' '+fdateformat(record.sdate)"></span>
          </b-button>
        </router-link>                
      </template>
      <template slot="actitle" slot-scope="record">
        <router-link
          :to="{
                      name:'Actinfo', 
                      params: { id: record.id}
                      }"
          target="_blank"
        >{{record.actitle}}</router-link>
      </template>
      <template slot="states" slot-scope="record">
        <span v-text="ctypes(record.states)"></span>
      </template>
      <template slot="action" slot-scope="record">        
        <a v-if="record.states=='N'"  @click="del(record)" href="javascript: void(0)" >
              <b-icon icon="trash" font-scale="1.2" variant="danger"></b-icon> 刪除 
        </a>         
        <router-link
          v-if="record.states=='N'"
          :to="{
                name:'Actedite', 
                params: { id: record.id, edit:'revise'}
                }"
        >
          <b-icon icon="pencil-square" font-scale="1.2"></b-icon>編輯
        </router-link>
        <a  @click="copyAct(record)" href="javascript: void(0)" >
              <b-icon icon="files" font-scale="1.2" ></b-icon> 複製 
        </a> 
        <a v-if="record.states=='Y' && fdateformat(record.sdate)>fdateformat(currentTime) "  @click="del(record)" href="javascript: void(0)" >
              <b-icon icon="trash" font-scale="1.2" variant="danger"></b-icon> 刪除
        </a>         
      </template>
    </a-table>
  </div>
</template>   
<script>
import * as moment from "moment/moment";
import { actService, macService } from "../../../_services";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = params => {
  return actService.Findactpagebystate(params);
};

const delData = params => {
  return macService.macDel(params);
}

const copyItem = params => {
  return macService.macCopy(params);
}

const columns = [
  {
    title: "活動日期",
    dataIndex: "",
    width: "15%",
    ellipsis: true,
    scopedSlots: { customRender: "sdate" }
  },
  {
    title: "活動名稱",
    dataIndex: "",
    width: "55%",
    ellipsis: true,
    scopedSlots: { customRender: "actitle" }
  },
  {
    title: "狀態",
    dataIndex: "",
    ellipsis: true,
    scopedSlots: { customRender: "states" },
    width: "10%"
  },
  {
    title: " 編輯 ",
    dataIndex: "",
    key: "id",
    ellipsis: true,
    scopedSlots: { customRender: "action" },
    width: "20%"
  }
];

export default {
  name: "Act_table",
  props: {
    ids: {
      type: String
    },
    items: {
      type: Array
    }
  },
  data() {
    return {
      pagination: { defaultPageSize: 4 },
      columns,
      loading: false,
      data: [],
      myPage:1,
      currentTime: moment(),
      locale:{
        filterConfirm:'確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },
    };
  },
  computed: {
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    //console.log("mounted-reload");
    const queryTableState = JSON.parse(window.sessionStorage.getItem('queryTableState'));
    if (queryTableState) {
      this.myPage =queryTableState.page
      this.reloadByPage()
    }else{
      this.reload();
    }

  },
  watch: {
    ids() {
      //console.log("watch-reload");
      this.reload();
    },
    defaultval() {
      //console.log("watch-defaultval");
      this.reload();
    }
  },
  methods: {
    handleTableChange(pagination, filters, sorter) {
      const pager = { ...this.pagination };
      pager.current = pagination.current;
      this.pagination = pager;
      this.myPage = pager.current;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        uid: this.defaultval.id,
        state: this.ids,
        ...filters
      });
      let queryTableState={
        page: this.myPage,
      }
      window.sessionStorage.setItem('queryTableState', JSON.stringify(queryTableState));
    },

    fetch(params = {}) {
      this.loading = true;
      queryData({
        ...params
      }).then(({ data }) => {
        //const pagination = { ...this.pagination };
        const pagination = { ...this.pagination, current: params.page, pageSize: params.results };
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.info.totail;
        //pagination.total = 200;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },

    copyAct(items){
      const self = this;
      this.$confirm({
        title:
          "活動複製後, 請至草稿編輯送審 ",
        content: "活動名稱 : " + items.actitle,
        okText: "Yes",
        okType: "primary",
        cancelText: "No",
        zIndex: 9999,
        async onOk() {
          await self.copyMyAct(items.id);
        },
        onCancel() {
          console.log("Cancel");
        }
      });      
    },

    async copyMyAct(id) {
      this.loading = true;
      let rsults = await this.CopyMyAct({id})  
      setTimeout(() => {
        if(rsults.data==null || rsults.data==''){
          this.reload();
        }  
      }, 1600);

    },
    
    async CopyMyAct(params={}){
     return await copyItem({
        ...params,
      }).then(({ data }) => {  
        let obj = data;      
        return obj
      });
    },     

    del(items){
      const self = this;
      this.$confirm({
        title:
          "您確定刪除該活動?",
        content: "活動名稱 : " + items.actitle,
        okText: "Yes",
        okType: "danger",
        cancelText: "No",
        zIndex: 9999,
        async onOk() {
          await self.removem(items.id);
        },
        onCancel() {
          console.log("Cancel");
        }
      });
    },   
    
    async removem(id) {
      this.loading = true;
      let rsults = await this.updata({id})  
      setTimeout(() => {
        if(rsults.data==null || rsults.data==''){
          this.reloadByPage();
        }   
      }, 1600);
      
    },    

    async updata(params={}){
     return await delData({
        ...params,
      }).then(({ data }) => {  
        let obj = data;      
        return obj
      });
    },    

    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    ctypes(val) {
      //return狀態 N草稿, I審核中, R發佈中, F結束, D下架
      let tmp = "";
      val == "N" ? (tmp = "草稿") : "";
      val == "I" ? (tmp = "審核中") : "";
      val == "Y" ? (tmp = "發佈中") : "";
      val == "F" ? (tmp = "結束") : "";
      val == "D" ? (tmp = "下架") : "";
      return tmp;
    },

    reload() {
      this.fetch({
        results: 4,
        page: 1,
        pageSize: 4,
        uid: this.defaultval.id,
        state: this.ids
      });
    },
    reloadByPage(){
      this.fetch({
        results: 4,
        page: this.myPage,
        pageSize: 4,
        uid: this.defaultval.id,
        state: this.ids
      });
    },
  },
};
</script>

<style scoped>
.myTable thead {
  background-color: #000000;
}
</style>
<style>
.hidden_cssd {
  border-top: 1px solid #f1f1f0;
  border-bottom: 3px solid #ebebe8;
  border-style: dotted;
}
</style>