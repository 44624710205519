<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col"><Topbar ref="con1" @inid="inid" /></div>
    </div>
  </div>        
</template>
<script>
import { mapState } from "vuex";
import Topbar from "./uscontrol/Act_topbar.vue"


export default {
  name: 'Act_my',   
  components: {
    Topbar,
  },
  data() {
    return {
      data: [],
    };
  },
  computed: {
    ...mapState("account", ["status", "user"]),
  },
  mounted() {
  },
  methods: {
    inid(){
      this.$refs["con2"].inid()
    }
  },
  beforeRouteLeave(to, from, next) {
    if(to.name!=='Act_date'){
      window.sessionStorage.removeItem('queryTableState');
      window.sessionStorage.removeItem('queryNewTabIndex');
    }
    next();
  }, 

};
</script>