<template>
  <div>
    <a-spin :spinning="spinning">
      <div class="spin-content">
        <b-container fluid>
          <!-- 新增年和月的選擇下拉選單 -->
          <b-row style="margin-top: -1.5rem;">
            <b-col cols="12" md="3">
              <b-form-group label="選擇年份">
                <b-form-select v-model="selectedYear" :options="years"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-form-group label="選擇月份">
                <b-form-select v-model="selectedMonth" :options="months"></b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="3">
              <b-button @click="fetchData" :disabled="selectedYear==null || selectedMonth==null" pill variant="primary" size="lg" block  class="mt-4">查詢</b-button>
            </b-col>
            <b-col cols="12" md="3">
              <a-alert v-if="selectedYear!==null && selectedMonth!==null" style="font-weight: bold;font-size: 18px;margin-top: 1.2rem;"
               :message="'查詢條件 : ' + selectedYear +'年'+ selectedMonth+'月'" type="info"  />
            </b-col>            
          </b-row>
          
          <b-row>         
            <b-col cols="12" md="3" v-for="(item, index) in chartData" :key="item.id">
              <b-card 
                :img-src="item.titurl !== '' ? item.titurl : mainImg('event_540.png')"
                img-alt="Image"
                img-top                
                :title="item.name"
                :style="{ 'margin': '5px', 'width': '20rem', 'max-width': '100%', 'border-radius': '20px', 'box-shadow': '0 4px 8px 0 rgba(0,0,0,0.2)' }"
                class="card-transition card-container mb-3">
                <div v-if="index === 0" style="position: absolute; top: -15px; right: -15px; z-index: 1;">
                  <b-icon icon="award-fill" class="rounded-circle bg-danger p-2" variant="light" font-scale="3.5"></b-icon>                            
                </div>                        
                <b-list-group flush>
                  <b-list-group-item>活動數: {{ item.act }}</b-list-group-item>
                  <b-list-group-item>參加人數: {{ item.joinid }}</b-list-group-item>
                  <b-list-group-item>報到人數: {{ item.hasjoin }}</b-list-group-item>
                  <b-list-group-item>實得學數: {{ item.score }}</b-list-group-item>
                </b-list-group>

                <b-button @click="goDetail(item)" block pill :disabled="selectedYear==null || selectedMonth==null" variant="info">
                  該單位所舉辦活動
                  <b-icon icon="zoom-in" aria-hidden="true"></b-icon>
                </b-button> 
                <b-button block pill v-if="item.type == 'town' && item.town_count > 0" :disabled="selectedYear==null || selectedMonth==null"  @click="goOrgDetail(item.id)" variant="primary">
                  旗下組織所舉辦活動                              
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-container>

        <b-modal id="modal-scrollable" ref="my-lines" size="lg" centered scrollable hide-header hide-footer>
          <div class="my-4">
            <div v-if="myFormData.name !== ''" id="stats-container" style="margin-left: 0.5rem;">
              <h4>{{ myFormData.name }} 活動資料統計</h4>
              <div class="stat">
                <label>活動數 總和 : </label>
                <span>{{ myFormData.act }}</span>
              </div>                  
              <div class="stat">
                <label>參加人數 總和 : </label>
                <span>{{ myFormData.joinid }}</span>
              </div>
              <div class="stat">
                <label>報到人數 總和 : </label>
                <span>{{ myFormData.hasjoin }}</span>
              </div>
              <div class="stat">
                <label>實得學數 總和 : </label>
                <span>{{ myFormData.score }}</span>
              </div>
            </div> 

            <b-card no-body v-for="item in chartDataDetail" :key="item.fid" style="margin: 0.5rem;" :header="item.actitle">
              <div v-if="item.score > 0" style="position: absolute; top: -15px; right: -15px; z-index: 1;">
                <b-icon icon="bell-fill" class="rounded-circle bg-primary p-2" variant="light" font-scale="2.5"></b-icon>                            
              </div>                      
              <b-list-group flush>
                <b-list-group-item>活動開始: {{ item.sdate }}</b-list-group-item>
                <b-list-group-item>活動結束: {{ item.edate }}</b-list-group-item>                                                
                <b-list-group-item>參加人數: {{ item.joinid }}</b-list-group-item>
                <b-list-group-item>報到人數: {{ item.hasjoin }}</b-list-group-item>
                <b-list-group-item :class="{'highlight-score': item.score > 0}">實得學數: {{ item.score }}</b-list-group-item>
              </b-list-group>
            </b-card>             
          </div>
          <b-tabs content-class="mt-3">
            <div v-for="(group, key) in chartOrgDataDetail" :key="key">                
              <b-tab :title="group[0].name">
                <div id="stats-container" style="margin-left: 0.5rem;">
                  <h4>{{ group[0].name }} 活動資料統計</h4>
                  <div class="stat">
                    <label>參加人數 總和 : </label>
                    <span>{{ totalJoinId(group) }}</span>
                  </div>
                  <div class="stat">
                    <label>報到人數 總和 : </label>
                    <span>{{ totalHasJoin(group) }}</span>
                  </div>
                  <div class="stat">
                    <label>實得學數 總和 : </label>
                    <span>{{ totalScore(group) }}</span>
                  </div>
                </div>                 
                <p>
                  <b-card no-body v-for="item in group" :key="item.fid" style="margin: 0.5rem;" :header="item.actitle">
                    <div v-if="item.score > 0" style="position: absolute; top: -15px; right: -15px; z-index: 1;">
                      <b-icon icon="bell-fill" class="rounded-circle bg-primary p-2" variant="light" font-scale="2.5"></b-icon>                            
                    </div>                      
                    <b-list-group flush>
                      <b-list-group-item>活動開始: {{ item.sdate }}</b-list-group-item>
                      <b-list-group-item>活動結束: {{ item.edate }}</b-list-group-item>                                                
                      <b-list-group-item>參加人數: {{ item.joinid }}</b-list-group-item>
                      <b-list-group-item>報到人數: {{ item.hasjoin }}</b-list-group-item>
                      <b-list-group-item :class="{'highlight-score': item.score > 0}">實得學數: {{ item.score }}</b-list-group-item>
                    </b-list-group>
                  </b-card>
                </p>
              </b-tab>
            </div>
          </b-tabs>
        </b-modal>
      </div>
    </a-spin>
  </div>
</template>

<script>
import * as moment from "moment/moment";
import { actService } from "../../../_services";
import { mapState } from "vuex";
import 'moment/locale/zh-tw';

const queryData = obj => {
  return actService.MyOrgScore(obj);
};
const queryDataDetail = obj => {
  return actService.MyOrgScoreDetail(obj);
};

const queryOrgDataDetail = obj => {
  return actService.MyOrgDataScoreDetail(obj);
};

export default {
  name: "Act_report",
  data() {
    return {
      spinning: false,
      chartData: [],
      chartDataDetail: [],
      chartOrgDataDetail: [],
      currentTime: moment(),
      myFormData: {},
      selectedYear: null,
      selectedMonth: null,
      years: Array.from({ length: 2 }, (_, i) => moment().year() - i),
      months: Array.from({ length: 12 }, (_, i) => ({ text: `${i + 1} 月`, value: (i + 1).toString().padStart(2, '0') })),
      locale: {
        filterConfirm: '確定',
        filterReset: '重置',
        emptyText: '暫時無任何數據'        
      },
    };
  },
  computed: {
    ...mapState("makeorg", ["defaultval"]),
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    defaultval() {
      this.chartData = [];
      this.chartDataDetail = [];        
      this.fetchData();
    }
  },
  methods: {
    fdateformat(val) {
      return moment(val).format("YYYY-MM-DD");
    },
    myReload() {
      this.spinning = true;
      queryData({ id: this.defaultval.id }).then(({ data }) => {
        this.spinning = false; 
        if (data !== undefined) {
          this.chartData = data.sort((a, b) => b.act - a.act);
        }
      });
    },
    fetchData() {
      if (this.selectedYear && this.selectedMonth) {
        this.chartData=[]
        this.spinning = true;
        queryData({ 
          id: this.defaultval.id,
          year: this.selectedYear,
          month: this.selectedMonth
        }).then(({ data }) => {
          this.spinning = false;
          if (data !== undefined) {
            this.chartData = data.sort((a, b) => b.act - a.act);
          }
        });
      } else {
        this.$bvToast.toast('請選擇年份和月份', {
          title: '提示',
          variant: 'warning',
          solid: true
        });
      }
    },
    goDetail(item) {
      if (this.selectedYear && this.selectedMonth) {      
      this.spinning = true;
      this.clearItem();
      this.myFormData.name = item.name;
      this.myFormData.act = item.act;
      this.myFormData.joinid = item.joinid;
      this.myFormData.hasjoin = item.hasjoin;
      this.myFormData.score = item.score;      
        queryDataDetail({ id: item.id ,
        year: this.selectedYear,
        month: this.selectedMonth
      }).then(({ data }) => {
        this.spinning = false;
        if (data !== null) {
          this.$refs["my-lines"].show();
          let tmp = data.sort((a, b) => new Date(a.sdate) - new Date(b.sdate));
          this.chartDataDetail = tmp;
        }        
      });
      }else{
        this.$bvToast.toast('請選擇年份和月份', {
          title: '提示',
          variant: 'warning',
          solid: true
        });
      }       

    }, 
    goOrgDetail(id) {
      if (this.selectedYear && this.selectedMonth) {  
      this.spinning = true;
      queryOrgDataDetail({ id,
        year: this.selectedYear,
        month: this.selectedMonth
       }).then(({ data }) => {
        this.spinning = false;
        this.clearItem();
        if (data !== null) {
          this.$refs["my-lines"].show();
          const groups = {};
          data.forEach((item) => {
            if (!groups[item.orgid]) {
              groups[item.orgid] = [];
            }
            groups[item.orgid].push(item);
          });
          this.chartOrgDataDetail = groups;
        }        
      });
      }else{
        this.$bvToast.toast('請選擇年份和月份', {
          title: '提示',
          variant: 'warning',
          solid: true
        });
      }
    },  
    clearItem() {
      this.chartDataDetail = [];
      this.chartOrgDataDetail = [];
      this.myFormData.name = "";
      this.myFormData.act = "";
      this.myFormData.joinid = "";
      this.myFormData.hasjoin = "";
      this.myFormData.score = "";      
    }, 
    mainImg(imgs) {
      return require(`../../../assets/${imgs}`);
    }, 
    totalJoinId(activities) {
      return activities.reduce((sum, activity) => sum + activity.joinid, 0);
    },
    totalHasJoin(activities) {
      return activities.reduce((sum, activity) => sum + activity.hasjoin, 0);
    },
    totalScore(activities) {
      return activities.reduce((sum, activity) => sum + activity.score, 0);
    }    
  }
};
</script>


<style scoped>
.myTable thead {
  background-color: #000000;
}

.highlight-score {
    background-color: #dff0d8; /* 选择一个明亮的绿色背景 */
    font-weight:bold;
}

.spin-content {
  
  padding: 30px;
}

#stats-container {
  background-color: rgb(242, 248, 227);
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 8px;
  margin: 20px;
  width: 98%;
}

#stats-container h4 {
  color: #333;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}

.stat {
  margin: 10px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd; /* 底線樣式 */
  padding-bottom: 8px; /* 增加底部間隔 */
}

.stat:last-child {
  border-bottom: none; /* 最後一個 stat 不顯示底線 */
}

.stat label {
  color: #555;
  font-weight: bold;
  margin-right: 5px;
}

.stat span {
  flex-grow: 1;
  text-align: right;
  color: #2c3e50;
}

</style>
