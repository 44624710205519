<template>
  <div>
    <div class="ddic">
        我的活動
        <div class="divs" id="style-3"> 
            <div>
                <b-tabs v-model="newIndex" content-class="mt-3" v-on:activate-tab="tabActivated">
                    <b-tab title="暫存檔" ><p><ActableTmp v-if="newIndex==0" /></p></b-tab>
                    <b-tab title="草稿" ><p><Actable v-if="newIndex-1==0"  :ids="converts(newIndex-1)" /></p></b-tab>
                    <b-tab title="審核中"><p><Actable v-if="newIndex-1==1"  :ids="converts(newIndex-1)" /></p></b-tab>
                    <b-tab title="已發佈" active><p><Actable v-if="newIndex-1==2" :ids="converts(newIndex-1)" /></p></b-tab>
                    <b-tab title="結束"><p><Actable v-if="newIndex-1==3" :ids="converts(newIndex-1)" /></p></b-tab>  
                    <b-tab v-if="defaultval.type=='town' || defaultval.type=='county'" title="報表"><p><Act_report/></p></b-tab>                     
                </b-tabs>
            </div>
        </div>  
          
    </div>
  </div>
  
</template>
<script>

import Act_report from "./Act_report.vue"
import Actable from "./Act_table.vue"
import ActableTmp from "./Act_table_tmp.vue"
import * as moment from "moment/moment";
import { mapState } from "vuex";

export default {
  name: 'Act_topbar',  
  components: {
    Actable, 
    ActableTmp,
    Act_report,
  },     
  data() {
    return {
      newIndex:3,
    };
  },
  computed: {
    ...mapState("makeorg", ["defaultval"])
  },
  mounted() {
    this.restoreTabState();
  },  
  methods: {
  restoreTabState() {
    const queryNewTabIndex = sessionStorage.getItem('queryNewTabIndex');
    if (queryNewTabIndex) {
      const savedState = JSON.parse(queryNewTabIndex);    
      this.$nextTick(() => {
        this.newIndex = savedState.page;  // 确保更新是在下一个事件循环，增加响应性
      });
    }
  },

  tabActivated(newTabIndex){
      const myIndex = sessionStorage.getItem('queryNewTabIndex');
      if (myIndex) {
        let savedState = JSON.parse(myIndex);
        if(newTabIndex!==savedState.page){
            window.sessionStorage.removeItem('queryTableState')
        }      
      }              
      this.newIndex =  newTabIndex  
      let queryNewTabIndex={
        page: this.newIndex,
      }
      window.sessionStorage.setItem('queryNewTabIndex', JSON.stringify(queryNewTabIndex));
  },       
  converts(val){
    let tmp = "Y";
      val == 0 ? tmp ="N" : "";
      val == 1 ? tmp ="I" : "";
      val == 2 ? tmp ="Y" : "";
      val == 3 ? tmp ="F" : "";      
      return tmp;
  },

  fdateformat(val){
        return moment(val).format("YYYY-MM-DD"); 
    },
  },
};
</script>

<style scoped>
#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #000000;
}

#style-3{
    text-align: left;
}
.ddic{
    padding:20px;
    font-size: 14px;
    text-align: left;    
    height:650px;
    margin: 13px auto;
	transition: all .3s ease;
}
.divs{
    overflow: auto;
    height:600px;
}
</style>
<style>
.hidden_cssd {
  border-top:1px solid #f1f1f0; 
  border-bottom:3px solid #ebebe8;
  border-style:dotted;
}
</style>